import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {

  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - अध्याय १ ला" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='अध्याय १ ला' num='01'/>
        <div id="main">
          <section id="content" className="main">
            <p>
श्री गणेशाय नम: । गणराया पडतो तुझ्या पाया । मी मूळचाच मंदमती । काय लिहिणार गुरू महती ॥ १ ॥ 
शब्दांचे नसे भंडार । जेणे घेतसे माघार । मनी ध्यानी मुर्ती ठसली । अहर्निष हृदयी वसली ॥ २ ॥ 
हे वाग्देवी सरस्वती । दे मज सन्मती । तुझा हुकूम झाल्यावरी । लेखणी आली माझ्या हाती ॥ ३ ॥ 
तुझ्या वाचून काय लिहिणार? । अज्ञानीच ठरणार । तू रहावे ह्या स्थानाला । वहातो कर कमले तुजला ॥ ४ ॥ 
माझे दोन्ही कर तुला अर्पिले । मी-तू पणा लुप्त झाले । माझे काही न उरले । तुझ्यातच सारे विलीन झाले ॥ ५ ॥
तुझ्या करांमध्ये पडली भर । मी मुळचाच पामर । वाटे घ्यावा तुझा आधार । काव्य शक्तीने कर उद्धार ॥ ६ ॥
माझा भार भुमीवरी । ठरलो निकामा व्यवहारी । ठरलो निकामा संसारी । आता तूच जवळ करणारी ॥ ७ ॥
लौकिकातही मी शून्य । तुजविण न तारे अन्य । तुझा कृपा पर्जन्य । जीवन करेल धन्य ॥ ८ ॥
कृपा करावी माझ्यावरी । विराजावे स्थानावरी । करकमळे स्वीकारणारी । आपलेच म्हणणारी ॥ ९ ॥
किती विनवू तुला । किती आळवू तुला । आता स्थिरावे स्थानाला । आरंभ करावे ग्रंथ लेखनाला ॥ १० ॥
माझे काही न उरले । तुलाच लिहीणे भाग पडले । पुन्हा पुन्हा विनवले । माझे सर्व सांगितले ॥ ११ ॥
तुझ्या वाचून सारे सुने । तुझ्या वाचून सारे उणे । नको नको वैभव उसने । दीन दुबळे परके जीणे ॥ १२ ॥
वृद्ध अबाल गोपाळात । तू दिसते सर्वात । तूच ठेवते आनंदात । जेणे रहाती एकोप्यात ॥ १३ ॥
जो तुझ्या सान्निध्यात । कधी न पोरका म्हणण्यात । तुझ्याच सहवासात । ग्रंथ निर्मिले जातात ॥ १४ ॥
तुझ्यामुळेच वेदपठण । तुझ्यामुळेच मंत्ररटण । तुझ्यामुळेच नामस्मरण । कैसे होईल विस्मरण ॥ १५ ॥
तुझ्या स्मरणे स्फुर्ती चैतन्य । विस्मरणे उदास जीवन । कुणास आवडेल भकास जीवन? । तुझ्या वीण रटाळ जीवन ॥ १६ ॥ 
आत्मा परमात्म्याचा अंश । शोधतो चैतन्यास । जेथे आत्म्याचा वास । तेथे चैतन्य सहवास ॥ १७ ॥
आत्मा शोधतो तुला । ‘तो’ नि ‘मी’ नसे वेगळा । परमेश्वराने पसारा मांडला । त्यानेच खेळ चालवला ॥ १८ ॥
जो हाताने अधू होणार । तो काय ग्रंथ लिहिणार? । तुझ्याकडेच धाव घेणार । पुन्हा पुन्हा विनवणार ॥ १९ ॥
जैसी तू लाभली संत पुरूषांस । दासगणू महाराजांस । तैसीच लाभो सर्वांस । हीच इच्छा माझ्या मनास ॥ २० ॥
दासगणुंनी वेडे केले । ग्रंथ प्रभावे गुरू ठसले । गुरूंचे महत्व ऐसे ठसवले । गुरूवीण जीवन व्यर्थ वाटले ॥ २१ ॥
सुरस लेखन गुरूजवळ । त्यानेच शोधले मूळ । म्हणुनच आलो तुझ्या जवळ । जाण मनोरथ सकळ ॥ २२ ॥
सुरस ग्रंथ वाचले । जेणे गुरू आळवले । जेणे अश्रु नयनातले । नकळत गाली ओघळले ॥ २३ ॥
ज्या गुरूमाऊलीने मला । नामघास भरवला । तैसाच भरवो एक एक मूढाला । हीच प्रार्थना तुजला ॥ २४ ॥ 
आम्ही सारेच मूढ । नाही कळत गूढ । तूच जाणते गूढ । तार एक एक मूढ ॥ २५ ॥
तुझ्याच सहवासाने । होतील गुरू दर्शने । तेणेच त्यांचे स्थिरावणे । जेणे सहाय्य होणे ॥ २६ ॥
श्री गुरूंनी माझ्या घरी यावे । माझ्या घरी स्थिरावे । भक्तांस सहाय्य करावे । जीवन धन्य करावे ॥ २७ ॥
हाच ध्यास मनी लागला । तू अंतर्ज्ञानाने जाणला । जेणे मज जवळ केला । जीव व्यर्थ न भटकला ॥ २८ ॥
जरी मी मूढ पामर । परी गूढ शोधुन काढणार । श्रद्धा माझी तुझ्यावर । टाकला भार तुझ्यावर ॥ २९ ॥
करकमळे वाहिली मनाने । जाणले तू अंतर्ज्ञाने । आता ग्रंथाचे लिहिणे । पुन्हा पुन्हा विनवणे ॥ ३० ॥
तू आलीस ह्या स्थानाला । हे पटले माझ्या मनाला । काय वाण प्रसन्नतेला ! । शांती लाभेल चित्ताला ॥ ३१ ॥
जो हा ग्रंथ वाचणार । त्याला मनःशांती लाभणार । सरस्वती ग्रंथ लिहिणार । काय उणे असणार ! ॥ ३२ ॥
शब्द नाही खुटणार । शब्द स्वयेच येणार । हृदयातले बोल असणार । कैसे खोटे ठरणार? ॥ ३३ ॥
हे सरस्वती गणपती । जाणतो तुमची महती । प्रार्थना करतो मूढमती । द्या मज सन्मती ॥ ३४ ॥
तुम्हास आळवतो गणामहाराज । विनवतो गणामहाराज । लिहावी गुरूलीला आज । ??? ॥ ३५ ॥
शेगावी उत्सव चालला । आनंदी आनंद झाला । आनंदाच्या सोहोळ्याला । भक्तगण उमटला ॥ ३६ ॥
जेथे गुरू प्रकटणार । तेथे आनंद उत्सव होणार । आनंद आनंदात रमणार । आनंदी आनंद होणार ॥ ३७ ॥
उत्सवात मिळतो आनंद । त्यातच भेटतो परमानंद । त्यातच सकळ वृंद । होतो सदा धुंद ॥ ३८ ॥
तेणे लाभते चैतन्य । पळुन जाते दुःख दैन्य । त्यात होता रममाण । चिंता काळजी विस्मरण ॥ ३९ ॥
ज्याला आनंद अनुभव । तोच तरतो भव । तेणे सर्व आनंदमय । गणामहाराजास अनुभव ॥ ४० ॥
गुरू सर्वांस लाभावा । तेणे आनंद लाभावा । माझा हेतु ओळखावा । मनोरथ पुर्ण व्हावा ॥ ४१ ॥
सरस्वतीने गणपतीने ओळखला । जेणे आले स्थानाला । सुरस ग्रंथ लेखनाला । त्यांनी आरंभ केला ॥ ४२ ॥
जे जे ग्रंथात शब्द असणार । ते गणामहाराजाचे नसणार । जो स्वतःस पार विसरणार । तो काय लिहिणार! ॥ ४३ ॥
जो हा ग्रंथ वाचणार । त्यास मनःशांती लाभणार । हे बोल खरे ठरणार । जो तो दुसर्‍यास वदणार ॥ ४४ ॥
जो हा ग्रंथ श्रवण करी । पुण्य फळ त्याचे पदरी । व्यर्थ नका हिंडू दारोदारी ।गुरू येणार तुमच्या घरी ॥ ४५ ॥
तोच भरवेल नामघास । जेणे शांती लाभेल चित्तास । अनुपम भाग्य उदयास । चिंता क्लेश अस्तास ॥ ४६ ॥
माझा गुरू खातो तुकडा । परी नाही हो भिकारडा । सांगे नामाचे पक्वान्न वाढा । त्यावर संतुष्ट केवढा ॥ ४७ ॥
गुरू ऐशा घरचे खाणार । जेथे श्रद्धायुक्त भक्ती असणार । भक्तीची महती पटवणार । भक्तीत तो रमवणार ॥ ४८ ॥
माझा गुरू कोण? । ज्याच्या मुखी गण गण । अहर्निश मंत्ररटण । ऐसा गुरू गजानन ॥ ४९ ॥
गजाननाची ऐका महती । जी लिहविते सरस्वती । सहाय्य करतो गणपती । जेणे होईल उत्कृष्ट कृती ॥ ५० ॥
गणामहाराज बनला दास । बसला तिच्या सान्निध्यास । वाहिली कर कमळे तिस । विनवू लागला लिहिण्यास ॥ ५१ ॥
सरस्वतीने युक्ती केली । ती त्याच्यात एकरूप झाली । लेखणी त्याच्या हातातली । तीच चालवू लागली ॥ ५२ ॥
गणामहाराजास ठेवले नामजपात । एका वेगळ्या चिंतनात । एका वेगळ्या तंद्रीत । एका वेगळ्या आनंदात ॥ ५३ ॥
जी जी प्रेरणा मिळाली । ती लेखणीत उतरवली । लेखणी सरसर चालली । सरस्वतीने कमाल केली ॥ ५४ ॥
होण्या पूर्ण मनोरथ । त्यांनी वाचावा गुरूग्रंथ । तेणे साधाल प्रपंच परमार्थ । गुरूलीलेत भरला अर्थ ॥ ५५ ॥
सरस्वती वदली आठवणी । डॉ. भिंगारकर कहाणी । त्याने केली तिस विनवणी । जैसी अनेक संतांनी ॥ ५६ ॥
डॉ. भिंगारकर । विनवू लागला वरचेवर । म्हणे तूच माझा आधार । तूच ग्रंथ लिहिणार ॥ ५७ ॥
ग्रंथ गद्यात लिहविला । मनोरथ पूर्ण केला । ग्रंथ प्रकाशीला गेला । त्यास आनंद फार झाला ॥ ५८ ॥ 
जो जो माझा उपासक । त्याची बनते मी रक्षक । गणामहाराज ऐसा उपासक । जेणे ग्रंथास कारक ॥ ५९ ॥
श्रद्धेने तळमळीने प्रसन्न होते । गणामहाराजास कळते । तळमळीने हाक मारण्याते । माझे येणे होते ॥ ६० ॥
गणामहाराजाची तळमळ । अंतर्ज्ञानाने जाणली सकळ । शब्द भांडार सकळ । खुले केले मी सकळ ॥ ६१ ॥
प्रसन्न त्याचेवर होऊन । कृपाशीष त्याते देऊन । त्यास लेखणी देऊन । आरंभिले ग्रंथ लेखन ॥ ६२ ॥
आदेश केला गुरुविषयी । सांगितले लिहविते तसे लिही । तेणे गुरूस संतोष होई । अतिशयोक्ती ठरणार नाही ॥ ६३ ॥
एकदा वर्‍हाड प्रांतात । खामगाव तालुक्यात । शेगाव नामे ग्रामात । सकळ जन दैन्यदुःखात ॥ ६४ ॥
जन आपत्तीने घेरता । आळवू लागतात भगवंता । म्हणे तूच विघ्नहर्ता । सकळ सुखदाता ॥ ६५ ॥
शेगाव दुःखात सापडले । जनांनी नाना प्रयत्न केले । पदरचे होते तेही गेले । प्रारब्ध भोग ऐसे जाहले ॥ ६६ ॥
दैन्यदुःख चौफेर । विपत्तीत पडली भर । विपत्तीस आला पूर । सर्वत्र माजला हाहाःकार ॥ ६७ ॥
दानवांचे साम्राज्य माजले । लोक उन्मत्त जाहले । केवळ खळगा भरण्यात रमलेले । कुणी कुणाचे न उरले ॥ ६८ ॥
बुद्धी भ्रष्ट झाली । शेगावला अवकळा आली । दीनदुबळ्यात भर पडली । प्रसन्नता न उरली ॥ ६९ ॥
परी त्यातले काही जन । मुळचेच पुण्यवान । भक्तीभावात रममाण । भोळ्या भावाचे क्रियमाण ॥ ७० ॥
पुण्यात्मा काय मागणार? । संत भगवंत मागणार । त्याचेच ऐकणार । तैसीच कृती करणार ॥ ७१ ॥
भगवंत भेटतो कुणास? । गुरूकृपा झाली ज्यास । जो नित्य रहातो नामास । गुरूकृपा होते त्यास ॥ ७२ ॥
ज्यास श्री गुरू लाभला । त्यास भगवंत लाभला । तो भवसागर तरला । तो जीवनी धन्य झाला ॥ ७३ ॥
भगवंत स्वतःचे अस्तित्व टिकवतो । धर्माचे रक्षण करतो । वेळोवेळीअवतार घेतो । वेळोवेळी खूण पटवतो ॥ ७४ ॥
सामान्यांचे व्यवहार वेगळे । त्यांचे व्यवहार वेगळे । हे मूढ जनांस न कळे । जेणे ते कोरडेच राहिले ॥ ७५ ॥
काहिंनी नवस केले । काहिंनी भक्तीभावे आळवले । कुणी सत्कर्म करते झाले । कुणी त्यास साकडे घातले ॥ ७६ ॥
कुणी भगवंतास गार्‍हाणे घातले । नको नको ते बोलले । परी भगवंताने स्मित केले । जनांच्या गाली अश्रू ओघळले ॥ ७७ ॥
सारे खवळले भगवंतावर । जैसे कामगार मालकावर । तैसे खवळले भगवंतावर । बोलू लागले वरचेवर ॥ ७८ ॥
जैसा कामगार करती संप । तैसा लोकांनी केला संप । काहिंनी उपोषण तप । काहिंनी नामजप ॥ ७९ ॥
म्हणे भगवंताने प्रसन्न व्हावे । आमचे दैन्यदुःख घालवावे । आमचे हट्ट पुरवावे । आम्हा कधी न पोरके करावे ॥ ८० ॥
बहरावीत कल्पवृक्ष फुले । सुगंध सुवास दरवळे । सुहास्य गाली खुले । अवनी आनंदाने डुले ॥ ८१ ॥
जेव्हा कामगार जाती संपास । मालक धाडे मुख्य माणसास । कायदा कानून जाणणार्‍यास । मनोव्यथा जाणणार्‍यास ॥ ८२ ॥
तैसाच भगवंत धाडतो । सूज्ञ-गुरू उपयुक्त ठरतो । भक्तांच्या व्यथा जाणतो । भगवंतास सांगतो ॥ ८३ ॥
कारखान्याचा मुख्य अधिकारी । जोडण्यास हुशार भारी । संप मिटवितो सत्वरी । कामगार खुष होती त्यावरी ॥ ८४ ॥
भगवंत त्याहुन हुशार । भक्तीच्या मोजमापावर । तळमळीने हाक मारल्यावर । गुरूस तो धाडणार ॥ ८५ ॥
वेळोवेळी गुरू प्रकटतो । भक्त भगवंत जोडतो । भक्तांस भक्तीत रमवतो । जेणे भगवंत खुष होतो ॥ ८६ ॥
तो दैन्यदुःख जाणतो । भगवंताचे लक्ष वेधतो । जो शुद्ध मनाने भक्ती करतो । त्याला कोठारातले देतो ॥ ८७ ॥
येथे शुद्ध मनास महत्व फार । तैसेच अवलंबे श्रद्धेवर । खर्‍याखुर्‍या तळमळीवर । खुण पटते वारंवार ॥ ८८ ॥
भगवंत न कधी सांगणार । गुरूस कधी धाडणार । तळमळीने हाक मारल्यावर । सहाय्यभूत होणार ॥ ८९ ॥
जेव्हा उत्कट भक्तीभाव प्रकटतात । भगवंत त्याचे दारात ।  गुरू रूपे धाव घेतात । चरणकमळे दिसतात ॥ ९० ॥
ऐसेच पुण्य उदयास । शेगावकरांच्या वाट्यास । तिष्ठत राहिलेल्या सर्वांस । गुरू धाडला शेगावास ॥ ९१ ॥
धन्य तो पातुरकर । धन्य ते शेगावकर । ज्यांना लाभले गुरूवर । गजानन दिगंबर ॥ ९२ ॥
धन्य तो बंकटशेठ । कुलकर्णी दामोदरपंत । ज्यांनी पाहिले मूर्तीमंत । खरेखुरे भगवंत ॥ ९३ ॥
बंकटलाल आणि कुलकर्णी । गुरूस ओळखले दोघांनी । त्यांचेपाशी जाऊनी । माथा ठेवला गुरूचरणी ॥ ९४ ॥
बोलले पातुरकरांप्रती । काय घालतो भोजनपंक्ती ? । अरे प्रत्यक्ष गुरूमूर्ती । उष्टी शीते वेचून खाती ॥ ९५ ॥
अरे ! गुरू तुझ्या दारात । काय पंक्ती घालतो घरात? । गुरू उष्टे खातात । परी न काही बोलतात ॥ ९६ ॥
अरे तुझ्या घरी ऋतुशांत । सत्पुरूष जन्मावा घरात । कुलदीपक नांदावा घरात । हाच हेतु मनात ॥ ९७ ॥
शके अठराशेस । माघ वद्य सप्तमीस । ऐन दुपारच्या समयास । गुरू गजानन प्रकटला दारास ॥ ९८ ॥
देवीदास पातुरकरांच्या दारी । वेडा पिसा प्रकटला दुपारी । उष्ट्या पत्रावळीवरी । संतुष्ट होत होती स्वारी ॥ ९९ ॥
जुनी पुराणी असलेली । अंगात बंडी घातलेली । पात्र पाणी पिण्यास जवळी । मूर्ती दिगंबर सावळी ॥ १०० ॥
कच्ची चिलिम हातात । नव्हती शेकली भट्टीत । तपोबळ अंगी झळकत । स्वारी बसली तंद्रीत ॥ १०१ ॥
नासाग्र दृष्टी शांत मुद्रेला । सोबत विलक्षण तेजाला । आप-पर भाव लोपलेला । योग्याची खुण पटवू लागला ॥ १०२ ॥
उष्ट्या पत्रावळी जेवू लागला । एक एक शीत वेचू लागला । अन्न परब्रम्ह पटवू लागला । उपदेश कृतीने दावू लागला ॥ १०३ ॥
बंकटलाल दामोदर । स्नेही दोघे बरोबर । दोघांनी पाहिले गुरूवर । आश्चर्यचकित कृतीवर ॥ १०४ ॥
म्हणे हा वेडा पिसा दिसतसे । जरी पांघरले वेडे पिसे । परी यास म्हणणे वेडे पिसे । हे उचित नसे ॥ १०५ ॥
दोघे पाहू लागले त्याप्रत । स्वारी एका वेगळ्या तंद्रीत । वेचीत होती एकेक शीत । चवीचे नव्हते भान त्याप्रत ॥ १०६ ॥
अरे ! अस्सल खाणीचा हिरा । खाणच आपल्या दारा । सांगू सर्वांना सत्वरा । आधी सांगू पातुरकरा ॥ १०७ ॥
अरे, पातुरकर । ताट वाढून आण सत्वर । हा दारात बैसला मुनीवर । जरी पिसा दिसे वरवर ॥ १०८ ॥
पातुरकर लगबगीने आत गेले । एक पात्र वाढुन आणले । योग्याने सर्व अन्न गोळा केले । एकत्र कालवून उदर भरले ॥ १०९ ॥
चवीबेचवीचे विचार न आले । जठराग्नी तृप्त केले । साक्षात ब्रह्मच अवतरले । खुण पटवण्या ऐसे केले ॥ ११० ॥
यथेच्छपणे जेवण केले । पाणीही न ते प्यायले । सर्व मंत्रमुग्ध झालेले । पाणी द्यायचे विसरले ॥ १११ ॥
बंकट म्हणे पातुरकरास । पाणी द्यावे प्यावयास । वाळा मिश्रित पाण्यास । घ्यावया गेले घरास ॥ ११२ ॥
पातुरकर घरास गेले । वाळ्याचे सुवासिक पाणी केले । एका गडूत आणले । परी बाहेर येता अवाक् झाले ॥ ११३ ॥
हाळात पाणी भरलेले । जनावरांसाठी असलेले । त्यातलेच पाणी प्यायले । वाळ्याचे पाणी तसेच राहिले ॥ ११४ ॥
त्याने खुणेने सांगितले । गढुळ, निर्मळ काही न उरले । हे व्यवहार तुमचे झाले । आमचे व्यवहार वेगळे ॥ ११५ ॥
रखरखत्या उन्हात । श्री गुरू आला दारात । होता खूण पटवत । कृतीतुनच झाला श्रेष्ठ ॥ ११६ ॥
जेथे मन, चित्त शुद्ध असणार । भोळा भाव कृतीत असणार । तेथेच गुरू प्रकटणार । वेळोवेळी खुण पटवणार ॥ ११७ ॥
आपण ओळखावे गुरूस । तो सदा निःस्पृहतेत । नच भुलतो उसन्या वैभवास । खुण पटवतो जनांस ॥ ११८ ॥
शेगावचे लोक धन्य । ज्यांना लाभले गुरू गजानन । जेथे प्रकटतात गजानन । कैसे टिकेल दैन्य? ॥ ११९ ॥
जो राहतो नामजपयुक्त । तोच राहतो संकटमुक्त । ज्यांना वाटे व्हावे संकटमुक्त । त्यांनी जगावे नामयुक्त ॥ १२० ॥
करावे नाही लागत शुद्ध चित्त । नामानेच शुद्ध चित्त । चित्त शुद्धीतच स्वस्थ चित्त । तेणेच होते शांत चित्त ॥ १२१ ॥
जो नामजपात राहतो । जो नामाचे प्रमाण मानतो । तेथे गुरू प्रकटतो । तेथेच गुरू स्थिरावतो ॥ १२२ ॥
गणामहाराजाची प्रार्थना । म्हणे यावे गुरू गजानना । लाभ व्हावा बडोदेकरांना । आनंद होईल सर्वांना ॥ १२३ ॥
सरस्वती सांगते सर्वांस । आधी भजावे नामास । तेणे श्री गुरू घरास । अनुपम भाग्य त्यास ॥ १२४ ॥
जो गुरूंचे ऐकतो । तोच जागृत राहतो । जो गुरूभेटीत अधीर असतो । तो कधी बधीर नसतो ॥ १२५ ॥
ज्याला गुरू भेट तळमळ असणार । तोच आर्तस्वर ऐकणार । तोच अंतर्मुख होणार । हृदयाचे बोल ऐकणार ॥ १२६ ॥
ज्याच्या कानी कवच कुंडले । आत्म्याच्या आवाजे तो डोले । पोषाखी न कवच कुंडले । परी नामाची ती कुंडले ॥ १२७ ॥
गुरू सांगे ऐसे वस्त्र ल्यावे । ज्याने चित्त शांत व्हावे । ऐसे परिधान न करावे । जेणे मन अशांत व्हावे ॥ १२८ ॥
आत्मा परमात्म्याचा बिंदू । संतोषता तराल भवसिंधू । नका म्हणू साधूस भोंदू । नका उगाच कुणास नींदू ॥ १२९ ॥
मनाचा हेतु शुद्ध असावा । भाव कृतीत दिसावा । वरवर उसना नसावा । हाच विचार ठसवावा ॥ १३० ॥
कृतीवरून जाते ओळखले । खोल अंतरंगातले । जे जे विचार मनातले । तेच जातात आचरले ॥ १३१ ॥
ऐशी वाचा असावी । कृतीशी सुसंगत असावी । वायफळ बडबड नसावी । कृती साजेशी असावी ॥ १३२ ॥
श्री गुरू गजानने ऐसे केले । उष्ट्या पत्रावळी जेवले । अन्न परब्रम्ह पटवले । सारे चकित झाले ॥ १३३ ॥
अंतरंगात जे जे आले । ते ते कृतीत दिसले । नच लौकिकात रमले । व्यवहारी गुरफटले ॥ १३४ ॥ 
जरी व्यवहारी दिसे वावगे । परी चमत्कृतीत नाही मागे । ब्रम्हे व्यापले चराचर अवघे । हेच कृतीतुन सांगे ॥ १३५ ॥
ब्रह्माने सर्व व्यापले । काही शिल्लक न उरले । गढुळ निर्मळ व्यापले । सुवास कुवास व्यापले ॥ १३६ ॥
गुरू होते मंत्रजपात । गुरू होते नामाच्या तालात । गुरू होते एका तंद्रीत । गुरू होते वेगळ्याच आनंदात ॥ १३७ ॥
म्हणुनच सांगे तुम्हास । नका विसरू नामास । जेथे नामाचा वास । तेथे गुरू सहवास ॥ १३८ ॥
जरी असावे प्रंपचात । जरी असावे कर्तव्य कर्मात । परी असावे नामजपात । तेणेच तराल भवसागरात ॥ १३९ ॥
नामानेच तुम्ही तराल । भेटीचा आनंद मिळवाल । तेणेच सर्व प्राप्त कराल । जीवन सुसंगत कराल ॥ १४० ॥
जो राहतो नामाच्या तालात । तेणेच राहतो नामाच्या नादात । गुरू बोल कानी घुमतात । गुरू चरण लाभतात ॥ १४१ ॥
गुरू भिकारी नामाचा । नच लौकिकाचा । नच उसन्या वैभवाचा । परी नामानेच तृप्त व्हावयाचा ॥ १४२ ॥
येथेही ऐसेच जाहले । गुरू गजानन जेवले । हाळाचे गढुळ पाणी प्यायले । वाळ्याचे पाणी तसेच राहिले ॥ १४३ ॥
गजाननाची कृती पाहुनी । सर्व गेले गहिवरूनी । माथा ठेवला समर्थ चरणी । ईच्छा होती मनोमनी ॥ १४४ ॥
हिरा ठेवावा कोन्दुनी । सर्व शेगावकरांनी । ईच्छा अंतर्ज्ञाने जाणुनी । समर्थ निघाले तेथुनी ॥ १४५ ॥
महाराज पळत निघाले । वायुगतीने ते धावले । कुणी न त्यांस अडवले । क्षणात ते अदृश्य झाले ॥ १४६ ॥
पुढिल कथा ज्या काही । वाचता द्वितीय अध्यायी । श्रवणाने आनंद हृदयी । वृद्धिंगत निश्चित होई ॥ १४७ ॥

शुभं भवतु ॥
श्री गणपती सरस्वतीर्पणमस्तु ॥
॥ इति श्रीगुरूगजाननलीला ग्रंथस्य प्रथमोऽध्यायः संपूर्णः ॥
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
